var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.allQuestions.length == 0
      ? _c(
          "div",
          { staticClass: "flex flex-center" },
          [
            _c("pulse-loader", {
              staticClass: "q-pa-xl q-ma-xl",
              attrs: { loading: true, color: "white", size: "2rem" },
            }),
          ],
          1
        )
      : _c(
          "div",
          {},
          [
            _vm.$q.platform.is.desktop
              ? _c("desktop-view", {
                  attrs: {
                    currentQuestion: _vm.currentQuestion,
                    categories: _vm.categories,
                    current_category: _vm.current_category,
                    current_answers: _vm.current_answers,
                    language: _vm.language,
                  },
                  on: {
                    answerClicked: function ($event) {
                      return _vm.handleClick($event)
                    },
                  },
                })
              : _vm._e(),
            _vm.$q.platform.is.mobile
              ? _c("mobile-view", {
                  attrs: {
                    currentQuestion: _vm.currentQuestion,
                    categories: _vm.categories,
                    current_category: _vm.current_category,
                    current_answers: _vm.current_answers,
                    language: _vm.language,
                  },
                  on: {
                    answerClicked: function ($event) {
                      return _vm.handleClick($event)
                    },
                  },
                })
              : _vm._e(),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }