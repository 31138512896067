/**
Author: Tyshan Prince (tyshantprince@gmail.com)
Date: July 09, 2019


Description:

Triage component

Manages user's triage state and current question information

Dynamically updates user report based on selected answers

*/




<template>
  <div>
    <div v-if="allQuestions.length == 0" class="flex flex-center">
      <pulse-loader
        class="q-pa-xl q-ma-xl"
        :loading="true"
        color="white"
        size="2rem"
      ></pulse-loader>
    </div>

    <div v-else class>
      <!-- show on Desktop Devices -->
      <desktop-view
        v-if="$q.platform.is.desktop"
        :currentQuestion="currentQuestion"
        :categories="categories"
        :current_category="current_category"
        :current_answers="current_answers"
        :language="language"
        @answerClicked="handleClick($event)"
      ></desktop-view>

      <!-- show on Mobile Devices -->
      <mobile-view
        v-if="$q.platform.is.mobile"
        :currentQuestion="currentQuestion"
        :categories="categories"
        :current_category="current_category"
        :current_answers="current_answers"
        :language="language"
        @answerClicked="handleClick($event)"
      ></mobile-view>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import MobileView from "../components/MobileView.vue";
import DesktopView from "../components/DesktopView.vue";

export default {
  name: "PageTriage",
  components: {
    MobileView,
    DesktopView,
    PulseLoader,
  },
  data() {
    return {};
  },
  created() {
    // Runs when triage page is created
    this.$store.dispatch("newAnalytics"); // creates a new database entry to hold user answer choices
    this.$store.dispatch("fetchSpreadsheet"); // fetches questions and answers from spreadsheet
  },
  methods: {
    handleClick(answer) {
      if (answer.choice)
        this.$store.dispatch("saveToDatabase", {
          questionTitle: this.currentQuestion.question,
          choice: answer.choice,
        });

      if (answer.AboutSituation) {
        this.$store.dispatch("AboutSituation", {
          data: answer.AboutSituation,
          category: answer.category,
          questionId: answer.questionId,
        });
      }

      if (answer.Help) {
        this.$store.dispatch("FindHelp", {
          data: answer.Help,
          category: answer.category,
          questionId: answer.questionId,
        });
      }
      if (answer.MoreInfo) {
        this.$store.dispatch("MoreInfo", {
          data: answer.MoreInfo,
          category: answer.category,
          questionId: answer.questionId,
        });
      }
      if (answer.NextStep1) {
        this.$store.dispatch("NextStep1", {
          data: answer.NextStep1,
          title: answer.NextStep1Title,
          category: answer.category,
          questionId: answer.questionId,
        });
      }

      if (answer.NextStep2) {
        this.$store.dispatch("NextStep2", {
          data: answer.NextStep2,
          title: answer.NextStep2Title,
          category: answer.category,
          questionId: answer.questionId,
        });
      }

      if (answer.NextStep3) {
        this.$store.dispatch("NextStep3", {
          data: answer.NextStep3, 
          title: answer.NextStep3Title,
          category: answer.category,
          questionId: answer.questionId,
        });
      }
      this.track(answer);
    },

    track(answer){
      this.$gtag.event('button_click', {
        'event_category': answer.category,
        'event_label': this.currentQuestion.question,
        'value': answer.choice,
      })
    },
  },
  computed: {
    ...mapGetters({
      allQuestions: "allQuestions", // all questions from vuex store
      allAnswers: "allAnswers", // all answers from vuex store
      currentQuestionID: "currentQuestionId", // current question based on state of application
      language: "getLanguage",
      previousQuestionID: "getPreviousQuestionId",
    }),
    currentQuestion() {
      // Gets the current question object based on the current question ID
      return this.allQuestions.filter(
        (question) => question.id == this.currentQuestionID
      )[0];
    },

    categories() {
      // Get all categories
      var list = [];
      this.allQuestions.forEach((question) => {
        if (!list.includes(question.category)) list.push(question.category);
      });
      return list;
    },

    current_category() {
      if (this.currentQuestion) {
        return this.categories.filter((category) => {
          return category == this.currentQuestion.category;
        })[0];
      }
    },

    current_answers() {
      var self = this;
      if (self.currentQuestion)
        return this.allAnswers.filter(function (answer) {
          return self.currentQuestion.answers
            .split(",")
            .includes(answer.id.toString());
        });
    },
  },
};
</script>

